html,
body {
    background-color: #C71633;
    height: 100%;
    margin: 0px;
}

.container {
    height: 100%;
    max-width: 100%;
    @media (max-width: 768px), (max-height: 768px) {
        height: auto;
    }
}

.btn-dark {
    background-color: #515151;
    border-color: #141414;
}

#logo-container {
    height: 100%;
    @media (max-width: 768px), (max-height: 768px) {
        height: auto;
    }
}

#nissan-logo {
    max-width: 600px;
}

footer {
    color: #fff;
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    @media (max-width: 768px), (max-height: 768px) {
        position: relative;
    }
}